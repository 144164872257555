<template>
    <div id="app">
        <img class="logo" src="./assets/jemersoft_logo.png" width="200" alt="Logo de Jemersoft" />
        <div class="container-principal">
            <main>
                <h1>Generador de firmas</h1>
                <form>
                    <div class="field">
                        <label aria-placeholder="Tu nombre y apellido" for="fullname">Nombre y apellido</label>
                        <input id="fullname" v-model="person.fullname" />
                    </div>

                    <div class="field">
                        <label aria-placeholder="Tu puesto en Jemer" for="role">Puesto</label>
                        <input id="role" v-model="person.role" />
                    </div>

                    <div class="field">
                        <label aria-placeholder="Tu número de teléfono" for="telephone">Número de teléfono</label>
                        <input placeholder="+54 351 1234567" id="telephone" v-model="person.telephone" type="tel" />
                    </div>

                    <div class="field">
                        <label aria-placeholder="Tu correo de Jemer" for="mail">Correo</label>
                        <input placeholder="tumail@jemersoft.com" id="mail" type="email" v-model="person.mail" />
                    </div>
                </form>
            </main>
            <section class="container">
                <div class="container-firma">
                    <firma :person="person"> </firma>
                </div>
                <p class="instructions">
                    Seleccioná todo lo que está dentro del cuadrado negro,
                    copiá, y pegalo en tu firma de Gmail
                </p>

                <a href="https://www.loom.com/share/5d0e304981ed487f82aee4c51e5a2b6b" class="boton" target="_blank">VER
                    INSTRUCTIVO</a>
            </section>
        </div>
    </div>
</template>

<script>
import Firma from "./components/Firma.vue";

export default {
    name: "App",
    data() {
        return {
            person: {
                fullname: "",
                role: "",
                mail: "",
                telephone: "",
            },
        };
    },
    components: {
        Firma,
    },
    methods: {},
};
</script>

<style lang="scss">
@mixin card {
    background-color: white;
    border-radius: 12px;
    box-shadow: #0000003d 0px 3px 8px;
}

body {
    margin: 0;

    @media screen and (min-width: 2000px) {
        font-size: 0.75vw;
    }
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #32445e;
    background: linear-gradient(36deg, #32445e 0%, #4f8398 50%, #7aafa5 100%);
    color: #2c3e50;
    padding: 0 10vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        margin-bottom: 5vh;
        width: 15vw;
    }
}

.container-principal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10vw;
    height: fit-content;
}

h1 {
    color: #2d3b57;
    text-align: left;
    margin: 0;
}

main,
.container {
    height: 100%;
}

main {
    padding: 2rem;
    @include card;

    form .field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0.5rem 0 1rem;

        &:first-child {
            margin-top: 1.5rem;
        }

        label {
            margin-bottom: 0.25rem;
        }

        input {
            border-radius: 5px;
            width: 100%;
            padding: 0.75rem 0.25rem;
            border: 1pt solid lightgray;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include card;
    padding: 2rem;

    .container-firma {
        border: 1pt solid black;
        padding: 1rem;

        display: flex;
        justify-content: center;
        width: fit-content;

        @media screen and (min-width: 1281px) {
            transform: scale(1.25);
        }
    }

    p.instructions {
        text-align: center;
        margin: 3rem 5vw 1.5rem;
    }

    .boton {
        background-color: #569ba9;
        color: white;
        text-decoration: none;
        padding: 0.75rem 1.25rem;
        font-weight: bold;
        border-radius: 7px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #437a85;
            transform: scale(1.05);
            box-shadow: #0000003d 0px 3px 8px;
        }
    }
}
</style>
