<template>
<table id="firma" cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;;">
    <tbody>
        <tr>
            <td>
                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;;">
                    <tbody>
                        <tr>
                            <td style="vertical-align: middle;">
                                <h3 color="#000000" class="sc-fBuWsC eeihxG" style="margin: 0px; font-size: 20px; color: rgb(0, 0, 0);"><span>{{person.fullname ? person.fullname : "Nombre Apellido"}}</span></h3>
                                <p color="#000000" font-size="large" class="sc-fMiknA bxZCMx" style="margin: 0px; color: rgb(0, 0, 0); font-size: 16px; line-height: 24px;"><span>{{person.role ? person.role : "Puesto"}}</span></p>
                            </td>
                            <td width="30">
                                <div style="width: 30px;"></div>
                            </td>
                            <td color="#002440" direction="vertical" width="1" class="sc-jhAzac hmXDXQ" style="width: 1px; border-bottom: none; border-left: 1px solid #c5c4c1;"></td>
                            <td width="30">
                                <div style="width: 30px;"></div>
                            </td>
                            <td style="vertical-align: middle;">
                                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;;">
                                    <tbody>
                                        <tr height="25" style="vertical-align: middle;">
                                            <td width="30" style="vertical-align: middle;">
                                                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="vertical-align: bottom;"><span width="11" class="sc-jlyJG bbyJzT" style="display: block;"><img src="https://cdn.jemersoft.com/email-signature/JS-iconos-firmas-cel.png" width="13" class="sc-iRbamj blSEcj" style="display: block;"></span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding: 0px; color: rgb(0, 0, 0);"><span color="#000000" class="sc-gipzik iyhjGb" style="text-decoration: none; color: rgb(0, 0, 0); font-size: 12px;">{{person.telephone ? person.telephone : "Teléfono"}}</span></td>
                                        </tr>
                                        <tr height="25" style="vertical-align: middle;">
                                            <td width="30" style="vertical-align: middle;">
                                                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="vertical-align: bottom;"><span width="11" class="sc-jlyJG bbyJzT" style="display: block;"><img src="https://cdn.jemersoft.com/email-signature/JS-iconos-firmas-mail.png" width="13" class="sc-iRbamj blSEcj" style="display: block;"></span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding: 0px; text-decoration: none !important;"><span color="#000000" class="sc-gipzik iyhjGb" style="text-decoration: none !important; color: rgb(0, 0, 0) !important; font-size: 12px;">{{person.mail ? person.mail : "Correo"}}</span></td>
                                        </tr>
                                        <tr height="25" style="vertical-align: middle;">
                                            <td width="30" style="vertical-align: middle;">
                                                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="vertical-align: bottom;"><span width="11" class="sc-jlyJG bbyJzT" style="display: block;"><img src="https://cdn.jemersoft.com/email-signature/JS-iconos-firmas-web.png" width="13" class="sc-iRbamj blSEcj" style="display: block;"></span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding: 0px;"><a href="https://jemersoft.com" color="#000000" class="sc-gipzik iyhjGb" style="text-decoration: none !important; color: rgb(0, 0, 0); font-size: 12px;"><span>jemersoft.com</span></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td>
                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;; width: 100%;">
                    <tbody>
                        <tr>
                            <td height="30"></td>
                        </tr>
                        <tr>
                            <td color="#002440" direction="horizontal" height="1" class="sc-jhAzac hmXDXQ" style="width: 100%; border-bottom: 1px solid #c5c4c1; border-left: none; display: block;"></td>
                        </tr>
                        <tr>
                            <td height="30"></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td>
                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;; width: 100%;">
                    <tbody>
                        <tr>
                            <td style="vertical-align: top;"><img src="https://cdn.jemersoft.com/email-signature/JS-Logo-firma-01.png" role="presentation" width="150" class="sc-cHGsZl bHiaRe" style="max-width: 150px; display: inline-block;"></td>
                            <td style="text-align: right; vertical-align: top;">
                                <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW" style="vertical-align: -webkit-baseline-middle; font-size: large; font-family: Trebuchet MS, Arial, sans-serif;; display: inline-block;">
                                    <tbody>
                                        <tr style="text-align: right;">
                                            <td><a href="https://www.linkedin.com/company/jemersoft" class="sc-hzDkRC kpsoyz" style="display: inline-block; padding: 0px;"><img src="https://cdn.jemersoft.com/email-signature/JS-iconos-firmas-LI.png" alt="linkedin" height="24" class="sc-bRBYWo ccSRck" style="max-width: 135px; display: block;"></a></td>
                                            <td width="5">
                                                <div></div>
                                            </td>
                                            <td><a href="https://www.instagram.com/jemersoft/" class="sc-hzDkRC kpsoyz" style="display: inline-block; padding: 0px;"><img src="https://cdn.jemersoft.com/email-signature/JS-iconos-firmas-IG.png" alt="instagram" height="24" class="sc-bRBYWo ccSRck" style="max-width: 135px; display: block;"></a></td>
                                            <td width="5">
                                                <div></div>
                                            </td>
                                            <td><a href="https://www.facebook.com/jemersoft" class="sc-hzDkRC kpsoyz" style="display: inline-block; padding: 0px;"><img src="https://cdn.jemersoft.com/email-signature/JS-iconos-firmas-FB.png" alt="facebook" height="24" class="sc-bRBYWo ccSRck" style="max-width: 135px; display: block;"></a></td>
                                            <td width="5">
                                                <div></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
</template>

<script>
export default {
    name: "Firma",
    props: ["person"],

    data() {
        return {

        }

    }

}
</script>

<style>

</style>
